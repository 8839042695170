import React from 'react'
import { SectionPage, SectionHeading, Container } from "../../components/Section"
import Map from "../../components/Map"

// import Img from 'gatsby-image'
// import { useStaticQuery, graphql } from "gatsby"

// const MapBottomImg = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       placeholderImage: file(relativePath: { eq: "map-bottom.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 1600) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
// }

const MapSection = (data) =>{
	return(
		<SectionPage
		overflow="hidden"
		ept="80px"
		xpt="60px"
		pt="40px"
		pb="0"
		bg="#1E4156">
			{/* <SectionHeading textAlign="center" color='#fff'>{data.data.title}</SectionHeading> */}
			{/* <SectionDescription color='#fff'><p>Nationwide services in almost all 50 states</p></SectionDescription> */}
			{/* <Description dangerouslySetInnerHTML={{__html: data.data.description.childMarkdownRemark.html}} /> */}
			<Container><SectionHeading textAlign="center" color='#fff' mb="20px">Cities We Serve In Florida</SectionHeading></Container>
			<Map />
		</SectionPage>
	)
}

export default MapSection